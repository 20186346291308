<template>
<div style="max-width: 800px">

  <div class="card-box">
    <h4>Recent Messages</h4>
    <p>View messages that were recently filtered</p>
    <CButton @click="loadPath('sms-filter/recent-messages')" color="info" class="card-box-button">
      Recent Messages
    </CButton>
  </div>

  <div class="card-box">
    <h4>Sender Override</h4>
    <p>Manage how messages from specific senders are filtered</p>
    <CButton @click="loadPath('sms-filter/sender-override')" color="info" class="card-box-button">
      Sender Override
    </CButton>
  </div>

  <div class="card-box">
    <h4>Stats</h4>
    <p>See the breakdown of how we filter messages</p>
    <CButton @click="loadPath('sms-filter/stats')" color="info" class="card-box-button">
      Stats
    </CButton>
  </div>



    <router-view name="dailyHeatmap"></router-view>


</div>
</template>

<script>
export default {
  name: 'SMS-Filter',
  methods: {
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path
      });
    },
  }
}
</script>
